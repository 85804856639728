.post {
  padding: 3rem 0;

  &-info {
    color: $default-tint;
    font-family: $serif-secondary;
    letter-spacing: 0.5px;
    text-align: center;

    span {
      font-style: italic;
    }
  }

  &-title {
    color: $default-shade;
    font-family: $sans-serif;
    font-size: 3rem;
    margin: 1rem 0;
    text-align: center;
  }

  &-line {
    border-top: 0.4rem solid $default-shade;
    display: block;
    margin: 0 auto 3rem;
    width: 4rem;
  }

  p {
    margin: 0 0 1.25rem;
    text-align: justify;
  }

  a:hover {
    text-decoration: underline;
  }

  img {
    margin: 2rem auto 2rem;
  }

  img + em {
    color: $default-tint;
    display: block;
    font-family: $sans-serif;
    font-size: 0.9rem;
    font-style: normal;
    text-align: center;
  }

  // CSS for making emoji inline
  img.emoji {
    display: inline-block;
    left: 0;
    transform: none;
    width: 1rem;
    height: 1rem;
    vertical-align: text-top;
    padding: 0;
    margin: 0;
  }
}

.post-tags {
  &-list {
    font-family: $sans-serif;
    font-size: 0.8rem;
    margin-top: 2rem;

    a {
      display: inline-block;
      margin: 0 0.1rem 0.2rem;
      padding: 0.2rem 0.5rem;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      color: $default-color;
      text-decoration: none;

      &:hover,
      &:active {
        background: rgba(0, 0, 0, 0.1);
        text-decoration: none;
      }
    }
  }

  &-item {
    &-icon {
      height: 1rem;
      vertical-align: middle;
      display: inline-block;
    }
  }
}
