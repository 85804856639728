* {
  @include box-sizing;
  line-height: 1.7;
}

html,
body {
  color: $default-color;
  margin: 0;
  padding: 0;
}

html {
  font-family: $serif-primary;
  font-size: 14px;
  overflow-y: scroll;

  @media (min-width: 600px) {
    font-size: 16px;
  }
}

body {
  -webkit-text-size-adjust: 100%;
  background-color: $background-grey;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $default-shade;
  font-family: $sans-serif;
  line-height: normal;
}

p, li {
  line-height: 1.75rem;
}


a {
  color: $blue;
  text-decoration: none;
}

.about-link:hover {
  text-decoration: underline;
}

blockquote {
  border-left: .25rem solid $grey-2;
  color: $grey-1;
  margin: 1.2rem 0rem 1.2rem 2rem;
  padding: .5rem 1rem;

  p:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 600px) {
    padding: 0 5rem 0 1.25rem;
  }
}

img {
  display: block;
  margin: 0 0 1rem;
  max-width: 100%;
  max-height: 600px;
}

figcaption {
  font-size: 0.8em;
  margin: 2em 0;
  text-align: center
}

td {
  vertical-align: top;
}
